import { render, staticRenderFns } from "./VoucherAmountConsumer.vue?vue&type=template&id=a4020460&scoped=true"
import script from "./VoucherAmountConsumer.vue?vue&type=script&lang=js"
export * from "./VoucherAmountConsumer.vue?vue&type=script&lang=js"
import style0 from "./VoucherAmountConsumer.vue?vue&type=style&index=0&id=a4020460&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4020460",
  null
  
)

export default component.exports