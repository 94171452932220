<template>
  <div class="purchase-consumer-gift-card">
    <v-overlay :value="orderProcessing">
      <v-progress-circular :size="50" indeterminate color="ultra-light" />
    </v-overlay>
    <pay-and-get-offer v-if="offer" :branding="branding" :restricted="restricted">
      <template #extraContent>
        <div style="width: 100%">
          <merchants-modal class="link-merchants" :offer_id="offer.id">
            <template #activator="{ on, attrs }">
              <h-link class="link-merchants" large v-bind="attrs" v-on="on">{{ $t('components.consumer_gift_card_payment_success.cta_merchants') }}</h-link>
            </template>
          </merchants-modal>
        </div>
        <div style="width: 100%">
          <consumer-gift-card-info-modal :show-action="false" :large-link="true" />
        </div>
      </template>
    </pay-and-get-offer>
    <h-promotion-progress-bar
      class="purchase-promotion-progress-bar"
      v-if="offer.current_promotion"
      :status="offer.current_promotion.status"
      :reward-percent="offer.current_promotion.incentive.bonus_display"
      :expiration="offer.current_promotion.availability.end_date"
      :start="offer.current_promotion.availability.start_date"
      :current-number="offer.current_promotion.availability.inventory.adjusted_avail_payment_amount"
      :total-number="offer.current_promotion.availability.inventory.adjusted_total_payment_amount"
      :min-purchase="offer.current_promotion.incentive.minimum_payment"
      :max-purchase="offer.current_promotion.incentive.adjusted_user_payment_limit"
    />

    <consumer-gift-card-amount-and-message :offer="offer" :partner-id="partnerId" />
    <div class="totals-form-layout" v-if="!hidePaymentSection">
      <consumer-gift-card-totals :offer-name="$options.filters.translateI18nObject(branding, 'label')" />
      <consumer-gift-card-payment-form :partner-id="partnerId" :offer-id="offer.id" :space="space" />
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import ConsumerGiftCardAmountAndMessage from '../../consumer-gift-card/components/ConsumerGiftCardAmountAndMessage.vue';
import ConsumerGiftCardInfoModal from '../../consumer-gift-card/components/ConsumerGiftCardInfoModal.vue';
import ConsumerGiftCardPaymentForm from '../../consumer-gift-card/components/ConsumerGiftCardPaymentForm.vue';
import ConsumerGiftCardTotals from '../../consumer-gift-card/components/ConsumerGiftCardTotals.vue';
import MerchantsModal from '../../modals/MerchantsModal.vue';
import PayAndGetOffer from '../../pay-and-get/PayAndGetOffer.vue';
export default {
  components: { ConsumerGiftCardTotals, ConsumerGiftCardPaymentForm, ConsumerGiftCardAmountAndMessage, PayAndGetOffer, ConsumerGiftCardInfoModal, MerchantsModal },
  props: {
    offer: {
      type: Object,
    },
    publisher: {
      type: Object,
    },
    partnerId: {
      type: String,
    },
    space: {
      type: Object,
    },
  },

  name: 'purchase-consumer-gift-card-payment',
  computed: {
    ...mapGetters('consumerGiftCard', ['orderProcessing', 'offerPromotionId', 'paymentAmount', 'contextUserPaymentRemaining']),
    branding() {
      return this.offer.offer_branding || {};
    },
    restricted() {
      return _get(this.offer, 'features.restricted_to_members', false);
    },
    hidePaymentSection() {
      return (this.offer.current_promotion && this.offer.current_promotion.status !== 'available' && this.offerPromotionId) || (this.paymentAmount > this.contextUserPaymentRemaining && this.offerPromotionId);
    },
  },
};
</script>

<style lang="scss" scoped>
.totals-form-layout {
  @media ($md-min) {
    display: flex;
    gap: var(--gap-xxlarge);
    & > div {
      width: 50%;
    }
  }
}

.link-merchants {
  margin: var(--gap) 0 var(--gap-xsmall) 0;
}

.purchase-promotion-progress-bar {
  border: var(--border-thin) solid var(--color-border) !important;
  padding: var(--gap) !important;
}
.purchase-consumer-gift-card:last-child {
  > *:not(:last-child) {
    margin-bottom: var(--gap);
  }
}
</style>
