<template>
  <div class="hello-my-account-view-layout">
    <account-email-pending-verification v-if="emailNeedVerification" />
    <user-profile />
    <overlay-request-card v-if="canRequestCard" :redirect-link="cardApplyRedirectLink" />

    <account-info-bar-card v-if="hasClassicCard && isCardToActivate" :notice="classicRenewNotice" :card="accountClassicCardToActivate" card-program-type="gpr" :label-card="$t('components.account_info_bar_base.my_card')" />
    <account-info-bar-card v-else-if="hasClassicCard" :card="accountClassicCard" card-program-type="gpr" :label-card="$t('components.account_info_bar_base.my_card')" />
    <account-info-bar-new-card v-if="!hasClassicCard && accountHasPendingApplication" />

    <hello-content-error :style="{ margin: 'var(--gap) var(--gap-page-side)' }" />

    <div v-if="working" class="space-section-spinner">
      <v-progress-circular :size="100" :width="7" color="primary" indeterminate />
    </div>

    <account-space-section v-if="!working" :show-empty-section="hasClassicCard || accountHasPendingApplication" :account-vouchers-by-space="accountVouchersByCardholderAndBySpace[accountCardholderId]" :account-subscriptions="accountSubscriptions" />

    <div v-for="(instantCard, index) of accountInstantCardList" :key="`instant-card-${index}`">
      <account-info-bar-card
        :card="instantCard"
        :notice="instantCard.active_current_card_public_token ? $t('components.account_info_bar_base.current_card_renew_notice', { card_public_token: $options.filters.publicToken(instantCard.active_current_card_public_token) }) : null"
        card-program-type="instant"
        :label-card="$t('components.instant_card_listing_header.card_label')"
      />
      <div v-if="working" class="space-section-spinner">
        <v-progress-circular :size="100" :width="7" color="primary" indeterminate />
      </div>
      <account-space-section v-if="!working" :show-empty-section="true" :account-vouchers-by-space="accountVouchersByCardholderAndBySpace[instantCard.cardholder_id]" :account-subscriptions="accountSubscriptions" />
    </div>

    <account-space-consumer-gift-card-header v-if="!working && Object.keys(accountConsumerGiftCardVouchersBySpace).length" />
    <account-space-consumer-gift-card-section v-if="!working" :account-consumer-gift-card-vouchers-by-space="accountConsumerGiftCardVouchersBySpace" />

    <recently-visited-space-widget />

    <overlay-app large v-if="hasAnyCard" />
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
import AccountInfoBarNewCard from '@/components/account/AccountInfoBarNewCard.vue';
import AccountInfoBarCard from '@/components/account/AccountInfoBarCard.vue';
import AccountEmailPendingVerification from '@/components/account/AccountEmailPendingVerification.vue';
import OverlayRequestCard from '@/components/overlays/components/OverlayRequestCard.vue';
import UserProfile from '@/components/account/components/UserProfile.vue';
import OverlayApp from '@/components/overlays/components/OverlayApp.vue';
import AccountSpaceSection from '@/components/account/AccountSpaceSection.vue';
import AccountSpaceConsumerGiftCardSection from '@/components/account/AccountSpaceConsumerGiftCardSection.vue';
import AccountSpaceConsumerGiftCardHeader from '@/components/account/AccountSpaceConsumerGiftCardHeader.vue';
import RecentlyVisitedSpaceWidget from '../../space/RecentlyVisitedSpaceWidget.vue';

export default {
  components: {
    RecentlyVisitedSpaceWidget,
    AccountInfoBarNewCard,
    AccountInfoBarCard,
    AccountEmailPendingVerification,
    OverlayRequestCard,
    UserProfile,
    OverlayApp,
    AccountSpaceSection,
    AccountSpaceConsumerGiftCardSection,
    AccountSpaceConsumerGiftCardHeader,
  },
  setup(props, { root }) {
    const { executeContent, working } = safeExecute(root.$store);
    return {
      executeContent,
      working,
    };
  },
  name: 'my-account-view',
  title: (args) => args.$t('page_title.my_account'),
  watch: {
    currentLocale() {
      this.$title = this.$t('page_title.my_account');
    },
  },
  computed: {
    ...mapGetters('account', [
      'accountCardholderId',
      'accountVouchersByCardholderAndBySpace',
      'accountConsumerGiftCardVouchersBySpace',
      'accountClassicCard',
      'accountInstantCardList',
      'accountSubscriptions',
      'canRequestCard',
      'hasClassicCard',
      'hasAnyCard',
      'emailNeedVerification',
      'accountHasPendingApplication',
      'accountClassicCardToActivate',
      'accountCardToActivatePublicToken',
      'accountClassicCardPublicToken',
      'isCardToActivate',
    ]),
    classicRenewNotice() {
      return this.accountClassicCardPublicToken !== this.accountCardToActivatePublicToken
        ? this.$t('components.account_info_bar_base.current_card_renew_notice', { card_public_token: this.$options.filters.publicToken(this.accountClassicCardPublicToken) })
        : null;
    },
    cardApplyRedirectLink() {
      return {
        url: window.location.href,
        label_fr: this.$t('components.card_application.redirect_link.return_to_account_label', 'fr'),
        label_en: this.$t('components.card_application.redirect_link.return_to_account_label', 'en'),
      };
    },
  },
  async created() {
    await this.executeContent({
      action: 'account/getAccount',
    });
  },
};
</script>

<style lang="scss" scoped>
.hello-my-account-view-layout {
  padding: 0;
  background-color: var(--color-ultra-light);
}

.space-section-spinner {
  text-align: center;
  margin: var(--gap) 0;
}

.recently-visited-space-widget {
  @media ($md-min) {
    margin-left: var(--gap-page-side);
    margin-right: var(--gap-page-side);
  }
}
</style>
