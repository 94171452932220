<template>
  <h-form ref="consumerForm" class="consumer-gift-card-amount-and-message" v-model="valid">
    <div class="promotion-input" v-if="offer.current_promotion">
      <v-radio-group v-model="promotionId" style="margin-top: 0" :row="!$vuetify.breakpoint.smAndDown" hide-details>
        <v-radio class="offer-promotion-radio-option" :value="null">
          <template #label>
            <p class="h-label-2" style="color: var(--color-text-primary)">{{ $t('components.consumer_gift_card_promotion_selector.without') }}</p>
          </template>
        </v-radio>
        <v-radio class="offer-promotion-radio-option" :value="offer.current_promotion.id">
          <template #label>
            <p class="h-label-2" style="color: var(--color-text-primary)">{{ $t('components.consumer_gift_card_promotion_selector.with', { reward_percent: offer.current_promotion.incentive.bonus_display }) }}</p>
          </template>
        </v-radio>
      </v-radio-group>
    </div>

    <div class="amount-section" v-if="!promotionId">
      <div class="amount-input">
        <h-pay-and-get-editable-input
          :label="$t('components.consumer_gift_card_amount_and_message.label')"
          :hint="$t('components.consumer_gift_card_amount_and_message.min_max', { min_amount: contextMinAmount, max_amount: contextMaxAmount })"
          v-model="amount"
          :rules="amountRules"
        />
      </div>
    </div>

    <div v-else-if="offer.current_promotion && offer.current_promotion.status !== 'available'">
      <div v-if="offer.current_promotion.status === 'sold_out'">{{ $t('components.consumer_gift_card_amount_and_message.promotion_not_available_sold_out') }}</div>
      <div v-if="offer.current_promotion.status === 'coming_soon'" style="color: var(--color-warning)">
        {{ $t('components.consumer_gift_card_amount_and_message.promotion_not_available_coming_soon', { date: $options.filters.formatShortDate(offer.current_promotion.availability.start_date) }) }}
      </div>
      <div v-if="offer.current_promotion.status === 'closed'">{{ $t('components.consumer_gift_card_amount_and_message.promotion_not_available_closed') }}</div>
    </div>
    <div v-else style="width: 100%">
      <div class="pay-and-get-editable">
        <h-pay-and-get-input
          v-if="offer.current_promotion"
          :is-in-error="paymentExceedMaxPaymentBecauseOfUserRestriction"
          :disable-plus="disablePlus(amount, offer.current_promotion.incentive.step, Math.min(contextMaxAmount, contextUserPaymentRemaining), amountLeft)"
          :disable-minus="disableMinus(amount, offer.current_promotion.incentive.step, contextMinAmount, amountLeft)"
          :plus-action="plus"
          :minus-action="minus"
          :label="$t('components.consumer_gift_card_amount_and_message.pay')"
          :amount="amount | currency | currencySymbol"
        ></h-pay-and-get-input>
        <h-pay-and-get-label
          v-if="offer.current_promotion"
          :notice="$t('components.pay_and_get_editable.bonus_notice', { date: $options.filters.formatShortDate(contextBonusExpirationDate) })"
          :label="$t('components.pay_and_get_editable.bonus')"
          :amount="offer.current_promotion.incentive.bonus_display | bonusSymbol"
          :is-using-hints="true"
          :bonus-expiring-date="contextBonusExpirationDate"
        />
        <h-pay-and-get-label :label="$t('components.pay_and_get_editable.receive')" :amount="amountObtained | currency | currencySymbol" />
      </div>
      <div v-if="paymentExceedMaxPaymentBecauseOfUserRestriction" style="color: var(--color-warning)">{{ $t('components.consumer_gift_card_amount_and_message.promotion_exceed_max_allowed') }}</div>
    </div>

    <div class="message-input" v-if="!promotionId || (offer.current_promotion && offer.current_promotion.status === 'available' && !paymentExceedMaxPaymentBecauseOfUserRestriction)">
      <h-textarea
        v-model="message"
        :label="$t('components.consumer_gift_card_amount_and_message.msg_label')"
        :placeholder="$t('components.consumer_gift_card_amount_and_message.msg_placeholder')"
        persistent-hint
        persistent-placeholder
        height="140"
        maxlength="150"
        :rules="messageRules"
        counter
      />
    </div>
  </h-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validateMaxLength, validateMaxNumLines, validateMaxValue, validateMinValue, validatePositiveInteger, validateRequiredGivenFieldLabel } from '@/composables/validations';
import HPayAndGetEditableInput from '@/components/system/inputs/HPayAndGetEditableInput.vue';
import { addAmount, disableMinus, disablePlus, remainingAmount, removeAmount } from '@/composables/offer-publisher-helper';
import _get from 'lodash/get';

export default {
  components: { HPayAndGetEditableInput },
  props: {
    offer: {
      type: Object,
    },
    partnerId: {
      type: String,
    },
  },
  setup() {
    return {
      disablePlus,
      disableMinus,
      addAmount,
      removeAmount,
      remainingAmount,
      validateRequiredGivenFieldLabel,
      validateMaxNumLines,
      validateMaxLength,
      validateMinValue,
      validateMaxValue,
      validatePositiveInteger,
    };
  },
  name: 'consumer-gift-card-amount-and-message',
  data() {
    return {
      valid: false,
      messageRules: [this.validateMaxLength(150), this.validateMaxNumLines(6)],
    };
  },
  watch: {
    promotionId(val) {
      if (val) {
        if (this.offer.current_promotion && this.amount % this.offer.current_promotion.incentive.step !== 0) {
          this.setConsumerGiftCardPaymentAmount(this.offer.current_promotion.incentive.default_payment);
        }

        if (this.offer.current_promotion && this.amount < this.offer.current_promotion.incentive.minimum_payment) {
          this.setConsumerGiftCardPaymentAmount(this.offer.current_promotion.incentive.minimum_payment);
        }
      }
    },
    isReadyToPay(val) {
      this.setAmountAndMessageAreValid(val);
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('consumerGiftCard', [
      'contextUserPaymentRemaining',
      'contextDormancyTrigger',
      'contextDormancyFixedFee',
      'contextBonusExpirationDate',
      'contextDormancyStartDate',
      'contextDormancyEnabled',
      'offerPromotionId',
      'contextDefaultAmount',
      'contextMinAmount',
      'contextMaxAmount',
      'paymentAmount',
      'giftCardMessage',
      'hasAContextAndContextIsReady',
      'contextLoading',
    ]),
    isReadyToPay() {
      if (this.$refs.consumerForm) {
        this.$refs.consumerForm.validate();
      }

      if (this.offerPromotionId) {
        return this.valid && !this.paymentExceedMaxPaymentBecauseOfUserRestriction && this.hasAContextAndContextIsReady;
      }

      return this.valid && this.hasAContextAndContextIsReady;
    },
    amountRules() {
      if (!this.hasAContextAndContextIsReady) {
        return () => [];
      }
      return () => [this.validateRequiredGivenFieldLabel(this.$t('components.consumer_gift_card_amount_and_message.label')), this.validateMinValue(this.contextMinAmount), this.validateMaxValue(this.contextMaxAmount), this.validatePositiveInteger()];
    },
    promotionId: {
      get() {
        return this.offerPromotionId;
      },
      set(id) {
        this.setConsumerGiftCardOfferPromotionId({ id, offer_id: this.offer.id, partner_id: this.partnerId });
      },
    },
    amount: {
      get() {
        return this.paymentAmount;
      },
      set(args) {
        this.setConsumerGiftCardPaymentAmount(args);
      },
    },
    message: {
      get() {
        return this.giftCardMessage;
      },
      set(args) {
        this.setGiftCardMessage(args);
      },
    },
    paymentExceedMaxPaymentBecauseOfUserRestriction() {
      if (this.contextLoading) {
        return false;
      }
      return this.amount > Math.min(this.contextMaxAmount, this.contextUserPaymentRemaining);
    },
    amountLeft() {
      if (this.offer.current_promotion) {
        return this.remainingAmount(
          this.offer.current_promotion.availability.total_amount,
          this.offer.current_promotion.availability.adjusted_used_payment_amount,
          this.offer.current_promotion.incentive.bonus_display,
          this.offer.current_promotion.availability.adjusted_total_payment_amount,
        );
      }
      return 0;
    },
    amountObtained() {
      return (1 + _get(this.offer.current_promotion, 'incentive.bonus_percent', 0)) * this.amount;
    },
  },
  methods: {
    ...mapActions('consumerGiftCard', ['setConsumerGiftCardOfferPromotionId', 'setAmountAndMessageAreValid', 'setConsumerGiftCardPaymentAmount', 'setGiftCardMessage']),
    plus() {
      const newAmount = this.addAmount(this.amount, this.offer.current_promotion.incentive.step, this.offer.current_promotion.incentive.default_payment);
      this.setConsumerGiftCardPaymentAmount(newAmount);
    },
    minus() {
      const newAmount = this.removeAmount(this.amount, this.offer.current_promotion.incentive.step, this.offer.current_promotion.incentive.default_payment);
      this.setConsumerGiftCardPaymentAmount(newAmount);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input--radio-group__input {
  justify-content: center;
}

.notice {
  color: var(--color-text-tertiary);
}

.offer-promotion-radio-option {
  border-radius: 5px;
  border: 2px solid var(--color-border-light);
  padding: 12px;
  min-width: 190px;
}

.h-pay-and-get-editable-input {
  margin-bottom: var(--gap);
}

.amount-section {
  display: block;
  margin-bottom: var(--gap-xxlarge);

  .amount-input {
    display: flex;
    justify-content: space-around;
  }
}

.consumer-gift-card-amount-and-message {
  display: block;
  padding: var(--gap-large);
  border: var(--border-thin) solid var(--color-border);

  @media ($sm-min) {
    display: flex;

    flex-wrap: wrap;
    justify-content: left;
    & > .amount-section {
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 1;
      margin-bottom: 0;
    }

    & > .message-input {
      flex-grow: 1;
      flex-shrink: 1;
    }

    & > .pay-and-get-editable,
    & > .promotion-input {
      flex-basis: 100%;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  @media ($md-min) {
    gap: var(--gap);
  }
}

.pay-and-get-editable {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: var(--gap-xlarge) 0 var(--gap-large);

  .h-pay-and-get-input {
    width: 100%;
    margin-bottom: var(--gap-large);

    @media ($sm-min) {
      width: auto;
      margin-bottom: 0;
    }
  }

  .h-error-label {
    width: 100%;
    padding-bottom: 0;
  }
}
</style>
