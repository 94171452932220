<template>
  <div class="success-gift-certificate-offer-with-card">
    <h2>{{ $t('components.success_gift_certificate_offer_with_card.title') }}</h2>
    <voucher-amount :style="{ maxWidth: 'fit-content', marginTop: '10px' }" :voucher="currentRedeemResult" />
    <p class="h-text-3" v-html="$t('components.success_gift_certificate_offer_with_card.sub_title')"></p>
    <h-link @click="toAccount" v-html="$t('components.success_gift_certificate_offer_with_card.cta_my_account')" />
    <h-link @click="closeHeaderDrawer">{{ $t('components.success_gift_certificate_offer_with_card.cta_close') }}</h-link>
    <overlay-app v-if="!currentCardIsRenewable" />
    <overlay-renew-card :current-card="currentCard" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OverlayApp from '../../components/OverlayApp.vue';
import OverlayRenewCard from '../../components/OverlayRenewCard.vue';
import VoucherAmount from '@/components/vouchers/VoucherAmount.vue';

export default {
  components: { OverlayApp, VoucherAmount, OverlayRenewCard },
  name: 'success-gift-certificate-offer-with-card',
  computed: {
    ...mapGetters('overlayRedeem', ['currentRedeemResult', 'currentCard', 'currentCardIsRenewable']),
  },
  methods: {
    ...mapActions('overlay', ['closeHeaderDrawer']),
    ...mapActions('overlayRedeem', ['setCanReroute']),
    toAccount() {
      this.setCanReroute(false);

      this.$router.pushNoDuplicate({ name: 'my-account' });
    },
  },
};
</script>

<style lang="scss" scoped>
.success-gift-certificate-offer-with-card {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  .v-btn {
    align-self: flex-start;
  }
}
</style>
