<template>
  <h-dialog ref="dialog" :title="$t('components.corpo_promotion_out_of_inventory_modal.title')" warning v-bind="$attrs" v-on="$listeners" max-width="680">
    {{ $t('components.corpo_promotion_out_of_inventory_modal.text') }}
    <template #action-footer>
      <h-btn @click="gotoDetail">{{ $t('components.corpo_promotion_out_of_inventory_modal.ok') }}</h-btn>
      <h-btn outlined @click="reset">{{ $t('components.corpo_promotion_out_of_inventory_modal.cancel') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
export default {
  name: 'corpo-promotion-out-of-inventory-modal',
  methods: {
    close() {
      this.$refs.dialog.close();
    },
    gotoDetail() {
      this.close();
      this.$emit('goto', 'detail');
    },
    reset() {
      this.close();
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss" scoped></style>
