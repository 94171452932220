<template>
  <div class="corpo-payment-step frame">
    <div class="frame-content">
      <hello-target-error target="paymentStep" class="target-error" style="margin-bottom: var(--gap)" use-specific-error-message />

      <corpo-payment-summary class="corpo-payment-summary" />

      <corpo-payment-form @goto="(args) => $emit('goto', args)" @reset="$emit('reset')" @next="$emit('next')" class="corpo-payment-form" />
    </div>
  </div>
</template>

<script>
import CorpoPaymentSummary from '@/components/corpo/order-process/CorpoPaymentSummary';
import CorpoPaymentForm from '@/components/corpo/order-process/CorpoPaymentForm';

export default {
  name: 'corpo-payment-step',
  components: { CorpoPaymentSummary, CorpoPaymentForm },
};
</script>

<style lang="scss" scoped>
.frame-content {
  @media ($md-min) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.target-error,
.header {
  width: 100%;
}

.corpo-payment-summary {
  margin-bottom: var(--gap-xxlarge);

  @media ($md-min) {
    margin-bottom: 0;
  }
}

.corpo-payment-summary,
.corpo-payment-form {
  @media ($md-min) {
    width: calc((100% - 60px) / 2);
  }
}
</style>
