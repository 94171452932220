<template>
  <fragment>
    <v-progress-circular v-if="!init" :style="{ marginTop: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" :color="brandingColor" indeterminate />
    <div v-else class="offer-activation-view">
      <h-hero-banner-partner v-if="currentSpaceSlug" :img-source-mobile="currentBrandingHorizontalLogo" :img-source-desktop="currentBrandingHeroSmall"></h-hero-banner-partner>
      <h-btn @click="backSpace" color="text-primary" inverted class="back-btn">
        <v-icon>mdi-menu-left</v-icon>
        {{ $t('components.activation_view.back') }}
      </h-btn>

      <div class="activation-content">
        <h1>{{ $t('components.activation_view.title') }}</h1>
        <p class="subtitle h-text-1">{{ $t('components.activation_view.subtitle') }}</p>
        <div class="button-section">
          <button class="action" @click="activationAction">
            <div class="h-label-2">{{ $t('components.activation_view.cta_redeem') }}</div>
            <img class="action-icon" :src="require(`@/assets/activation-code-${currentLocale}.svg`)" />
          </button>
          <button class="action" @click="routeToInstant">
            <div class="h-label-2">{{ $t('components.activation_view.cta_instant_card') }}</div>
            <img class="action-icon" :src="require('@/assets/activation-card.svg')" />
          </button>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import safeExecute from '@/composables/safe-execute';

import { mapActions, mapGetters } from 'vuex';

export default {
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },

  name: 'offer-activation-view',
  components: {},
  data() {
    return {
      init: false,
      offer_id: null,
      publisher_id: null,
    };
  },
  async created() {
    let offer;

    if (this.$route.params.offer_id) {
      offer = await this.getOfferById(this.$route.params.offer_id);
    } else {
      offer = await this.getOfferBySlug(this.$route.params.slug);
    }

    // If no offer route to not found
    if (!offer) {
      this.$router.replaceNoDuplicate({ name: 'not-found', params: { space: this.currentSpaceSlug, _position: { x: 0, y: window.scrollY } } });
      return;
    }

    this.publisher_id = offer.publisher_id;
    this.offer_id = offer.id;
    this.init = true;
  },

  title: (args) => args.$t('page_title.offer_activation'),
  computed: {
    ...mapGetters('space', ['currentPartnerId', 'currentSpaceSlug', 'currentBrandingHeroSmall', 'currentBrandingHorizontalLogo', 'brandingColor']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('navigation', ['getBackRoute', 'backRouteIsSpaceHome']),
    ...mapGetters('overlay', ['headerDrawerIsOpen']),
    ...mapGetters('overlayRedeem', ['currentRedeemIsSuccessful', 'canReroute']),
  },
  watch: {
    currentRedeemIsSuccessful() {
      if (this.currentRedeemIsSuccessful && !this.headerDrawerIsOpen && this.canReroute) {
        this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { space: this.currentSpaceSlug, offer_id: this.offer_id } });
      }
    },
    headerDrawerIsOpen() {
      if (this.currentRedeemIsSuccessful && !this.headerDrawerIsOpen && this.canReroute) {
        this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { space: this.currentSpaceSlug, offer_id: this.offer_id } });
      }
    },
  },
  methods: {
    ...mapActions('overlay', ['openHeaderDrawerWithParams']),
    backSpace() {
      this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { space: this.currentSpaceSlug, offer_id: this.offer_id } });
    },
    activationAction() {
      this.openHeaderDrawerWithParams({
        content: 'redeem',
        payload: {
          offer_id: this.offer_id,
          publisher_id: this.publisher_id,
          partner_id: this.currentPartnerId,
        },
      });
    },
    routeToInstant() {
      this.$router.pushNoDuplicate({ name: 'instantCardActivationSpace', params: { space: this.currentSpaceSlug }, query: { fromOfferId: this.offer_id } });
    },
    async getOfferBySlug(slug) {
      return this.execute(
        {
          action: 'offer/getOfferDetailBySlug',
        },
        { partnerId: this.currentPartnerId, slug },
      );
    },

    async getOfferById(offer_id) {
      return this.execute(
        {
          action: 'offer/getOfferDetail',
        },
        offer_id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-content {
  background-color: var(--color-ultra-light);
  padding: var(--gap-xxlarge) var(--gap);
  text-align: center;
}

.subtitle {
  margin-top: var(--gap);
  margin-bottom: var(--gap-xxlarge);
}

.v-btn.back-btn {
  min-width: 0 !important;
  height: 60px;
  padding: 0 24px;
  margin-top: var(--gap);
}
.button-section {
  @media ($sm-min) {
    display: flex;
    gap: var(--gap);
    & > *:first-child {
      margin-left: auto;
    }
    & > *:last-child {
      margin-right: auto;
    }
  }
}

.action {
  min-height: 245px;
  display: block;
  width: 100%;
  border-radius: 20px;
  border: 2px solid var(--color-border);

  padding: var(--gap-xxlarge) var(--gap);
  text-align: center;
  transition: 0.3s;
  & > *:not(:last-child) {
    margin-bottom: var(--gap-xxlarge);
  }
  margin-bottom: var(--gap);

  @media ($sm-min) {
    max-width: 350px;
  }
}
.action:hover {
  border: 2px solid var(--color-branding);
  box-shadow: 0px 0px 0px 3px color-mix(in srgb, rgba(var(--color-branding-rgb-values), 0.4), white);
}

.action-icon {
  max-height: 140px;
}
</style>
