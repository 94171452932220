<template>
  <v-hover v-slot="{ hover }">
    <small-card-base
      :to-url="urlToOffer"
      :class="{ 'on-hover': hover }"
      :image="offer.offer_branding.images.showcases[currentLocale][0]"
      :show-panier-bleu="offer.offer_branding.features.panier_bleu"
      :offer-title="offer.offer_branding.label[currentLocale]"
    >
      <template #content>
        <small-card-base-badge use-gift-icon :text="offer.incentive.bonus_display" :region="offer.offer_branding.region_label[currentLocale]" :category_name="offer.category_name[currentLocale]" />

        <h-promotion-progress-bar
          v-if="offer.current_promotion && (offer.current_promotion.status === 'available' || offer.current_promotion.status === 'coming_soon')"
          class="progress-bar"
          :font-size="13"
          :status="offer.current_promotion.status"
          :reward-percent="offer.current_promotion.incentive.bonus_display"
          :expiration="offer.current_promotion.availability.end_date"
          :start="offer.current_promotion.availability.start_date"
          :current-number="offer.current_promotion.availability.inventory.adjusted_avail_payment_amount"
          :total-number="offer.current_promotion.availability.inventory.adjusted_total_payment_amount"
          :min-purchase="offer.current_promotion.incentive.minimum_payment"
          :max-purchase="offer.current_promotion.incentive.adjusted_user_payment_limit"
          restriction-label-key="components.consumer_gift_card_offer_small_card.promotion_restriction"
          mobile
        />
        <small-card-base-description :text="offer.offer_branding.description[currentLocale]" v-else />

        <small-card-voucher-status-label :cta-purchase="offer.cta.purchase" :status="offer.status" :start="offer.availability.start_date" />
      </template>
    </small-card-base>
  </v-hover>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import SmallCardBaseDescription from '@/components/cards/small/SmallCardBaseDescription.vue';
import SmallCardVoucherStatusLabel from '@/components/cards/small/SmallCardVoucherStatusLabel.vue';

export default {
  components: { SmallCardBase, SmallCardBaseDescription, SmallCardBaseBadge, SmallCardVoucherStatusLabel },
  name: 'gift-certificate-offer-single-publisher-small-card',
  props: {
    image: {
      type: String,
    },
    offer: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug']),
    urlToOffer() {
      return `/${this.currentSpaceSlug}/offers/${this.offer.id}`;
    },
  },
  methods: {
    ...mapActions('overlay', ['openHeaderDrawerWithParams']),
  },
};
</script>

<style scoped>
* {
  text-decoration: none !important;
}

::v-deep .consumer-gift-card-promotion-title > span {
  background-color: var(--color-error);
  color: white;
  padding: 2px 4px;
  font-size: var(--font-size-label-3);
  line-height: 1.5;
  font-weight: 600;
}
.h-promotion-progress-bar {
  padding: 0px !important;
  background-color: transparent !important;
}
</style>
