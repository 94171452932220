import Vue from 'vue';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _map from 'lodash/map';

async function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function createCompanyPayload(company) {
  const payload = {
    name: company.name,
    number_of_employees: company.number_of_employees,
    contact: {
      name: company.contact.name,
      email: company.contact.email,
      phone: company.contact.phone,
      phone_extension: company.contact.phone_extension,
    },
    address: {
      street: company.address.street,
      city: company.address.city,
      state: company.address.state,
      zip: company.address.zip,
      country: 'CA',
    },
  };

  payload.contact.phone = payload.contact.phone.replace(/[(\)\+]/g, '').replaceAll(/ /g, '-');

  return payload;
}

export default {
  namespaced: true,
  state: {
    offerId: null,
    offerPromotionId: null,

    order: {
      batch_quantities: [],
      message: '',
    },

    companiesLoading: false,
    selectedCompanyId: null,
    companyList: [],

    contextLoading: false,
    orderProcessing: false,

    context: null,
    contextOfferDescription: null,
    contextFeeCovered: false,
    contextFeeDetails: null,
    contextMinimumFee: null,
    contextTaxDetails: null,
    contextOrderDetails: null,
    contextTotal: null,
    contextQuantity: null,
    purchaseContextToken: null,

    contextDormancyEnabled: false,
    contextDormancyTrigger: null,
    contextDormancyFixedFee: 0,
    contextDormancyExtension: null,
    contextIncentiveModel: null,
    contextIncentiveBonusDurationDays: null,
    contextIncentiveBonusEndDate: null,
    offerCashFlowOptimization: null,

    corporateOrderId: null,
    corporateOrderStatusLoading: false,
    corporateOrderCompleted: null,
    corporateOrderBatchId: null,
  },
  getters: {
    currentCorpoOfferId: (state) => state.offerId,
    currentOfferPromotionId: (state) => state.offerPromotionId,
    order: (state) => state.order,
    selectedCompanyIndex: (state) =>
      _findIndex(state.companyList, function (company) {
        return company.id === state.selectedCompanyId;
      }),
    selectedCompanyId: (state) => state.selectedCompanyId,
    companyList: (state) => state.companyList,
    companiesLoading: (state) => state.companiesLoading,
    orderProcessing: (state) => state.orderProcessing,
    hasAContextAndContextIsReady: (state) => state.context !== null && !state.contextLoading,
    contextLoading: (state) => state.contextLoading,
    contextOfferDescription: (state) => state.contextOfferDescription,
    contextFeeCovered: (state) => state.contextFeeCovered,
    contextFeeDetails: (state) => state.contextFeeDetails,
    contextOrderDetails: (state) => state.contextOrderDetails,
    contextMinimumFee: (state) => state.contextMinimumFee,
    contextTaxDetails: (state) => state.contextTaxDetails,
    contextTotal: (state) => state.contextTotal,
    contextQuantity: (state) => state.contextQuantity,
    corporateOrderId: (state) => state.corporateOrderId,
    corporateOrderStatusLoading: (state) => state.corporateOrderStatusLoading,
    corporateOrderCompleted: (state) => state.corporateOrderCompleted,
    corporateOrderBatchId: (state) => state.corporateOrderBatchId,

    contextDormancyEnabled: (state) => state.contextDormancyEnabled,
    contextDormancyTrigger: (state) => state.contextDormancyTrigger,
    contextDormancyFixedFee: (state) => state.contextDormancyFixedFee,
    contextDormancyExtension: (state) => state.contextDormancyExtension,
    contextIncentiveModel: (state) => state.contextIncentiveModel,
    contextIncentiveBonusDurationDays: (state) => state.contextIncentiveBonusDurationDays,
    contextIncentiveBonusEndDate: (state) => state.contextIncentiveBonusEndDate,
    offerCashFlowOptimization: (state) => state.offerCashFlowOptimization,
  },
  mutations: {
    resetContext(state) {
      state.context = null;
      state.contextFeeDetails = null;
      state.contextTaxDetails = null;
      state.contexMinimumFeeDetails = null;
      state.contextOrderDetails = null;
      state.contextTotal = null;
      state.contextQuantity = null;
      state.purchaseContextToken = null;
      state.contextDormancyEnabled = true;
      state.contextDormancyTrigger = null;
      state.contextDormancyFixedFee = 0;
      state.contextDormancyExtension = null;
      state.contextIncentiveModel = null;
      state.contextIncentiveBonusDurationDays = null;
      state.contextIncentiveBonusEndDate = null;
      state.offerCashFlowOptimization = null;
    },
    resetOrder(state) {
      state.order = {
        quantity: 0,
        amount: 0,
        message: '',
      };
    },
    reset(state) {
      state.companiesLoading = false;
      state.selectedCompanyId = null;
      state.companyList = [];

      state.contextLoading = false;
      state.orderProcessing = false;

      state.corporateOrderId = null;
      state.corporateOrderStatusLoading = false;
      state.corporateOrderCompleted = null;
      state.corporateOrderBatchId = null;
    },
    setOfferPromotionId(state, offerPromotionId) {
      state.offerPromotionId = offerPromotionId;
    },
    setOfferId(state, offerId) {
      state.offerId = offerId;
    },
    setOrder(state, order) {
      state.order.batch_quantities = _map(order.batch_quantities, (batch_quantity) => {
        return {
          unit_amount: batch_quantity.amount,
          quantity: batch_quantity.quantity,
        };
      });
      state.order.message = order.message;
    },
    selectCompany(state, index) {
      state.selectedCompanyId = _get(state.companyList, `${index}.id`, null);
    },
    selectedCompanyId(state, companyId) {
      state.selectedCompanyId = companyId;
    },
    companyList(state, companyList) {
      state.companyList = companyList;
    },
    companiesLoading(state, loading) {
      state.companiesLoading = loading;
    },
    corporateOrderId(state, id) {
      state.corporateOrderId = id;
    },
    corporateOrderStatusLoading(state, loading) {
      state.corporateOrderStatusLoading = loading;
    },
    setCorporateOrderStatus(state, { completed, activation_code_batch_id }) {
      state.corporateOrderCompleted = completed;
      state.corporateOrderBatchId = activation_code_batch_id;
    },
    selectDefaultCompanyIfNoneOrNoLongerExists(state, selectedCompany = undefined) {
      const noCompanySelected = state.selectedCompanyId === null;
      const selectedCompanyDNE = selectedCompany === undefined;
      if (noCompanySelected || selectedCompanyDNE) {
        state.selectedCompanyId = _get(state.companyList, '0.id', null);
      }
    },
    setContext(state, context) {
      state.context = context;
      state.contextFeeCovered = _get(context.fee, 'covered', false);
      state.contextFeeDetails = {
        amount: _get(context.fee, 'amount', null),
        description: _get(context.fee, 'description'),
      };

      state.contextOfferDescription = _get(context, 'offer.description');
      state.contextOrderDetails = _map(_get(context.offer, 'batch_quantities', null), (batch_quantity) => {
        return {
          quantity: batch_quantity.quantity,
          amount: batch_quantity.amount,
          description: batch_quantity.description,
        };
      });
      state.contextTaxDetails = {
        amount: context.tax_amount,
        tax_types: context.tax_items.map((t) => t.type),
      };
      state.contextMinimumFee = _get(context.fee, 'minimum_fee', null);
      state.contextTotal = context.total_amount;
      state.contextQuantity = _get(context.fee, 'quantity', null);
      state.purchaseContextToken = context.purchase_context_token;

      state.contextDormancyEnabled = _get(context, 'offer.dormancy_enabled', false);
      state.contextDormancyTrigger = _get(context, 'offer.dormancy_last_activity_trigger_in_months', null);
      state.contextDormancyFixedFee = _get(context, 'offer.dormancy_fee', 0.0);
      state.contextDormancyExtension = _get(context, 'offer.dormancy_extension_in_months', null);

      state.contextIncentiveModel = _get(context, 'offer.incentive_model', null);
      state.contextIncentiveBonusDurationDays = _get(context, 'offer.incentive_bonus_duration_days', null);
      state.contextIncentiveBonusEndDate = _get(context, 'offer.incentive_bonus_end_date', null);

      state.offerCashFlowOptimization = _get(context, 'offer.offer_cash_flow_optimization', null);
    },
    setOrderProcessing(state, processing) {
      state.orderProcessing = processing;
    },
    setContextLoading(state, loading) {
      state.contextLoading = loading;
    },
  },
  actions: {
    resetCorpoStore({ commit }) {
      commit('resetContext');
      commit('resetOrder');
      commit('reset');
    },
    resetOrder({ commit }) {
      commit('resetOrder');
    },
    setOfferId({ commit }, offerId) {
      commit('setOfferId', offerId);
    },
    setOfferPromotionId({ commit }, offerPromotionId) {
      commit('setOfferPromotionId', offerPromotionId);
    },
    setOrder({ commit }, order) {
      commit('setOrder', order);
    },

    async pay({ commit, dispatch, state }, { merchant_ref_number, payment_method_token, recaptcha_token, tracking_link_id, visitor_uuid, postal_code }) {
      commit('setOrderProcessing', true);

      await dispatch('security/generateVisitorData', undefined, { root: true });

      const payload = {
        payment_method: {},
        merchant_ref_number,
        recaptcha_token,
        purchase_context_token: state.purchaseContextToken,
        tracking_link_id,
        visitor_uuid,
        postal_code,
      };
      if (payment_method_token) {
        payload.payment_method.token = payment_method_token;
      }

      try {
        const { corporate_order_id } = await Vue.prototype.$services.corpo.createPurchaseCorporateOrder(payload);
        if (window.hj) {
          hj('event', 'pay_corporate_offer');
        }
        await commit('corporateOrderId', corporate_order_id);
      } finally {
        await dispatch('offer/refreshCurrentOfferPromotion', undefined, { root: true });
        commit('setOrderProcessing', false);
      }
    },
    async updateCompany({ dispatch, commit }, company) {
      const payload = createCompanyPayload(company);
      await Vue.prototype.$services.corpo.updateBusiness(company.id, payload);
    },
    async createCompany({ dispatch }, company) {
      const payload = createCompanyPayload(company);
      await Vue.prototype.$services.corpo.createBusiness(payload);
      dispatch('account/getAccount', null, { root: true });
    },
    async getCompaniesAndSelectDefault({ dispatch }) {
      await dispatch('getCompanies');
      await dispatch('selectCompanyWithDefault');
    },
    async getCompanies({ commit }) {
      commit('companiesLoading', true);
      try {
        const businesses = await Vue.prototype.$services.corpo.listBusinesses();
        await commit('companyList', businesses);
      } finally {
        commit('companiesLoading', false);
      }
    },
    selectCompanyWithDefault({ state, commit }, index = undefined) {
      if (index === undefined) {
        const selectedCompany = _find(state.companyList, function (company) {
          return company.id === state.selectedCompanyId;
        });
        commit('selectDefaultCompanyIfNoneOrNoLongerExists', selectedCompany);
      } else {
        commit('selectCompany', index);
      }
    },
    async getCorporateOrderStatus({ commit, dispatch }, corporate_order_id) {
      commit('corporateOrderStatusLoading', true);
      const corporateOrderStatus = await Vue.prototype.$services.corpo.getCorporateOrderStatus(corporate_order_id);
      commit('setCorporateOrderStatus', corporateOrderStatus);
      commit('corporateOrderStatusLoading', false);
      if (corporateOrderStatus.completed) {
        dispatch('account/getAccount', null, { root: true });
      }
    },
    async reloadCompaniesThenResetSelection({ dispatch, commit }) {
      await dispatch('getCompanies', null);
      commit('selectCompany', 0);
    },
    async acquireCorporateContext({ commit, state, rootState, dispatch }, locale) {
      commit('setContextLoading', true);

      const payload = {
        business_id: state.selectedCompanyId,
        batch_quantities: state.order.batch_quantities,
        offer_id: state.offerId,
        locale,
      };

      if (!!state.order.message) {
        payload.personalized_message = state.order.message;
      }
      if (state.offerPromotionId) {
        payload.offer_promotion_id = state.offerPromotionId;
      }
      try {
        /* await sleep(500);
        throw new Error("whatever"); */
        const context = await Vue.prototype.$services.corpo.acquireCorporateOrderContext(payload);
        commit('setContext', context);
      } catch (err) {
        commit('resetContext');
        throw err;
      } finally {
        commit('setContextLoading', false);
      }
    },
    async downloadActivationCodesBatchExcel(context, { language, id }) {
      const corpoService = Vue.prototype.$services.corpo;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { content, filename } = await corpoService.downloadActivationCodesBatchExcel(id, language);
      await fileSaver.saveAs(content, filename);

      return { success: true };
    },
    async downloadActivationCodesBatchPdf(context, { language, id }) {
      const corpoService = Vue.prototype.$services.corpo;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const result = await corpoService.downloadActivationCodesBatchPdf(id, language);
      await fileSaver.saveAs(result, `codes-${id}.pdf`);

      return { success: true };
    },
    async downloadActivationCodesBatchInfosPdf(context, { language, id }) {
      const corpoService = Vue.prototype.$services.corpo;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const result = await corpoService.downloadActivationCodesBatchInfosPdf(id, language);
      await fileSaver.saveAs(result, `info-${id}.pdf`);

      return { success: true };
    },
    async downloadActivationCodesBatchZip(context, { language, id }) {
      const corpoService = Vue.prototype.$services.corpo;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const result = await corpoService.downloadActivationCodesBatchZip(id, language);
      await fileSaver.saveAs(result, `codes-${id}.zip`);

      return { success: true };
    },
    async downloadCorporateOrderInvoice(context, { corporate_order_id, language }) {
      const corpoService = Vue.prototype.$services.corpo;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await corpoService.downloadOrderInvoice(corporate_order_id, language);
      await fileSaver.saveAs(fileContent, filename);

      return { success: true };
    },
  },
};
