<template>
  <div class="corpo-order-process">
    <v-overlay :value="orderProcessing">
      <v-progress-circular :size="50" indeterminate color="white" />
    </v-overlay>

    <corpo-promotion-progress-bar v-if="currentStep !== 4 && currentStep !== '4'"></corpo-promotion-progress-bar>

    <v-stepper v-model="currentStep" non-linear>
      <h-stepper-header>
        <h-stepper-step step="1" :editable="highestStep > 1 && currentStep !== 4" :complete="currentStep > 1">{{ $t('components.corpo_order_process.step_order_title') }}</h-stepper-step>
        <v-divider />
        <h-stepper-step step="2" :editable="highestStep >= 2 && !requireOrderResubmit && currentStep !== 4" :complete="currentStep > 2">{{ $t('components.corpo_order_process.step_company_title') }}</h-stepper-step>
        <v-divider />
        <h-stepper-step step="3" :editable="highestStep > 2 && !requireOrderResubmit && currentStep !== 4" :complete="currentStep > 3">{{ $t('components.corpo_order_process.step_payment_title') }}</h-stepper-step>
      </h-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <corpo-order-step @next="nextStep" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <corpo-company-step @next="nextStep" />
        </v-stepper-content>

        <v-stepper-content step="3">
          <corpo-payment-step @next="nextStep" @goto="gotoStep" @reset="reset" />
        </v-stepper-content>

        <v-stepper-content step="4">
          <corpo-confirmation-step @reset="reset"></corpo-confirmation-step>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';

import CorpoOrderStep from '@/components/corpo/order-process/CorpoOrderStep';
import CorpoPromotionProgressBar from '@/components/corpo/order-process/CorpoPromotionProgressBar';
import CorpoCompanyStep from '@/components/corpo/order-process/CorpoCompanyStep';
import CorpoPaymentStep from '@/components/corpo/order-process/CorpoPaymentStep';
import CorpoConfirmationStep from '@/components/corpo/order-process/CorpoConfirmationStep';

export default {
  setup(props, { root }) {
    const { executeTarget, working } = safeExecute(root.$store);
    return {
      executeTarget,
      working,
    };
  },
  name: 'corpo-order-process',
  components: {
    CorpoPromotionProgressBar,
    CorpoOrderStep,
    CorpoCompanyStep,
    CorpoPaymentStep,
    CorpoConfirmationStep,
  },
  data() {
    return {
      currentStep: 1,
      highestStep: 1,
      STEPS_NAME: ['none', 'detail', 'entreprise', 'payment'],
    };
  },
  computed: {
    ...mapGetters('corpo', ['order', 'orderProcessing']),
    ...mapGetters('ui', ['currentLocale']),
    requireOrderResubmit() {
      return this.currentStep == 1;
    },
  },
  watch: {
    currentStep() {
      this.clearError();

      if (this.currentStep == 2) {
        this.executeTarget({
          action: 'corpo/getCompaniesAndSelectDefault',
          target: 'companyStep',
        });
      }
      if (this.currentStep == 3) {
        this.executeTarget(
          {
            action: 'corpo/acquireCorporateContext',
            target: 'paymentStep',
          },
          this.currentLocale,
        );
      }

      if (window.hj) {
        hj('stateChange', `/instant/order_process/${this.STEPS_NAME[this.currentStep]}`);
      }
    },
  },
  mounted() {
    this.resetOrder();
  },
  methods: {
    ...mapActions('corpo', ['resetOrder']),
    ...mapActions('error', ['clearError']),
    nextStep() {
      this.currentStep++;
      this.highestStep = Math.max(this.highestStep, this.currentStep);
    },
    gotoStep(stepName) {
      this.currentStep = this.STEPS_NAME.indexOf(stepName);
    },
    reset() {
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss" scoped>
.corpo-order-process {
  padding: 0 var(--gap-page-side);
}
.v-stepper__content {
  padding: 0 !important;
}
</style>
