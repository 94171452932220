<template>
  <div class="claim-gift-certificate-sign-in-overlay">
    <div aria-live="polite">
      <v-expand-transition>
        <h-error-box class="claim-gift-certificate-overlay-content" v-if="currentOfferIsComingSoon" :text="$t('components.purchase_sign_in_overlay.error_offer_coming_soon')" />
        <h-error-box class="claim-gift-certificate-overlay-content" v-if="currentOfferIsClosed" :text="$t('components.purchase_sign_in_overlay.error_offer_closed')" />
      </v-expand-transition>
    </div>

    <hello-overlay-error />

    <offer-display-info v-if="offer" :branding="branding" :support-branding="supportBranding" :offer_id="offer.id" :publisher_id="publisher.publisher_id" :offer="offer" />

    <div class="claim-gift-certificate-overlay-content">
      <claim-gift-certificate-auth v-if="currentStep === 'auth'" :offer="offer" :publisher="publisher" />
      <claim-gift-certificate-redeem v-if="currentStep === 'redeem'" :offer="offer" :publisher="publisher" @completed="isRedeemedCompleteCallback" :space="space" />
      <claim-gift-certificate-success v-if="currentStep === 'success'" />
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import safeExecute from '@/composables/safe-execute';
import { isClosed, isComingSoon } from '@/composables/offer-helper';

import { recaptcha } from '@/mixins';
import { mapGetters, mapActions } from 'vuex';

import ClaimGiftCertificateRedeem from './claim-gift-certificate/ClaimGiftCertificateRedeem.vue';
import ClaimGiftCertificateAuth from './claim-gift-certificate/ClaimGiftCertificateAuth.vue';
import ClaimGiftCertificateSuccess from './claim-gift-certificate/ClaimGiftCertificateSuccess.vue';
import OfferDisplayInfo from './claim-gift-certificate/components/offer-display-info.vue';

export default {
  name: 'claim-gift-certificate-sign-in-overlay',
  components: {
    OfferDisplayInfo,
    ClaimGiftCertificateRedeem,
    ClaimGiftCertificateAuth,
    ClaimGiftCertificateSuccess,
  },
  setup(props, { root }) {
    const { execute, executeOverlay, working } = safeExecute(root.$store);
    return {
      execute,
      executeOverlay,
      working,
    };
  },
  mixins: [recaptcha],
  computed: {
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('overlay', ['headerDrawerContentPayload']),
    branding() {
      return this.offer.is_single_publisher ? this.offer.offer_branding : this.publisher.branding;
    },
    supportBranding() {
      return this.offer.is_single_publisher ? {} : this.offer.offer_branding;
    },
    currentOfferIsComingSoon() {
      return isComingSoon(this.offer);
    },
    currentOfferIsClosed() {
      return isClosed(this.offer);
    },
    currentStep() {
      if (this.authenticated) {
        if (this.isRedeemedComplete) {
          return 'success';
        }
        return 'redeem';
      }
      return 'auth';
    },
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.$emit('startInitialization');
        this.executeOverlay(
          {
            target: 'generic',
            action: 'overlayRedeem/getRedeemDetailForOverlay',
            success: () => {
              this.$emit('initialized');
            },
          },
          this.headerDrawerContentPayload,
        );
      }
    },
  },
  data() {
    return {
      isRedeemedComplete: false,
      space: {},
      offer: {},
      publisher: {},
    };
  },
  methods: {
    ...mapActions('overlayRedeem', ['setCanReroute']),
    isRedeemedCompleteCallback() {
      this.isRedeemedComplete = true;
    },
    async init() {
      this.$emit('startInitialization');
      this.setCanReroute(true);
      if (this.headerDrawerContentPayload.partner_id) {
        this.space = await this.execute(
          {
            action: 'overlayContent/getSpaceForOverlay',
          },
          this.headerDrawerContentPayload.partner_id,
        );
      }
      if (this.headerDrawerContentPayload.offer_id) {
        this.offer = await this.execute(
          {
            action: 'overlayContent/getOfferForOverlay',
          },
          this.headerDrawerContentPayload.offer_id,
        );
      }
      if (this.headerDrawerContentPayload.publisher_id && this.headerDrawerContentPayload.offer_id) {
        this.publisher = await this.execute(
          {
            action: 'overlayContent/getOfferPublisherForOverlay',
          },
          {
            offer_id: this.headerDrawerContentPayload.offer_id,
            publisher_id: this.headerDrawerContentPayload.publisher_id,
          },
        );
      }
      if (this.headerDrawerContentPayload.publisher_id && this.headerDrawerContentPayload.offer_id && this.headerDrawerContentPayload.partner_id) {
        await this.executeOverlay(
          {
            target: 'generic',
            action: 'overlayRedeem/getRedeemDetailForOverlay',
          },
          this.headerDrawerContentPayload,
        );
      }

      this.$emit('initialized');
    },
    async clear() {
      await this.executeOverlay({
        action: 'overlayRedeem/clearOverlayRedeemStore',
        success: function () {
          this.isRedeemedComplete = false;
          this.space = {};
          this.offer = {};
          this.publisher = {};
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .hello-overlay-error .h-error-box,
.claim-gift-certificate-overlay-content {
  padding: var(--gap) var(--gap-xlarge);
  @media ($sm-min) {
    padding: 0;
  }
}
::v-deep .offer-text {
  padding-left: var(--gap-xlarge);
  padding-right: var(--gap-xlarge);
  @media ($sm-min) {
    padding-left: 0;
    padding-right: 0;
  }
}
.claim-gift-certificate-sign-in-overlay {
  @media ($sm-min) {
    padding: var(--gap) var(--gap-xlarge);
  }
}

::v-deep .h-error-box {
  margin-bottom: var(--gap);
}
</style>
