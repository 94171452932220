<template>
  <div class="voucher-amount">
    <div class="main-section">
      <voucher-base-amount v-bind="voucherMainProps" :amount-style-large="true" />
    </div>

    <div class="secondary-section" v-if="showAmountDetails">
      <voucher-base-amount v-bind="voucherContributionProps" />
      <voucher-base-amount v-bind="voucherBonusProps" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import VoucherBaseAmount from './VoucherBaseAmount.vue';
import moment from 'moment-timezone';

export default {
  components: { VoucherBaseAmount },
  name: 'voucher-amount-consumer',
  props: {
    voucher: {
      type: Object,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showAmountDetails() {
      return this.voucher.bonus_amount > 0 && this.voucher.payment_amount > 0 && this.voucher.status !== 'transferred' && this.voucher.status !== 'canceled';
    },
    ...mapGetters('ui', ['currentLocale']),
    isBonusAmountOnly() {
      return this.voucher.payment_amount === 0 && this.voucher.bonus_amount > 0;
    },
    isPaymentOnly() {
      return this.voucher.payment_amount > 0 && this.voucher.bonus_amount === 0;
    },
    hasDormancyFee() {
      return this.voucher.payment_amount > 0 && _get(this.voucher, 'dormancy.enabled', false);
    },
    hasClosureDate() {
      return this.voucher.closure_date !== null;
    },
    voucherMainProps() {
      if (this.voucher.status === 'transferred') {
        return {
          amount: this.voucher.payment_amount + this.voucher.bonus_amount,
          boldAmount: true,
        };
      }
      if (this.isBonusAmountOnly) {
        return {
          text: this.$t('components.account_voucher.actual_balance'),
          subText: this.$t('components.account_voucher.bonus_expiration', { date: this.$options.filters.formatShortDate(this.voucher.bonus_expiration_date) }),
          amount: this.voucher.payment_amount + this.voucher.bonus_amount,
          boldAmount: true,
        };
      }
      if (this.isPaymentOnly) {
        if (this.hasDormancyFee) {
          const dormancyAmount = this.$options.filters.currency_full(this.voucher.dormancy.settings.fixed_fee);
          const amount = this.$options.filters.currencySymbol(this.$options.filters.currency(dormancyAmount));

          return {
            text: this.$t('components.account_voucher.contribution'),
            subText: this.$t('components.account_voucher.dormancy.dormancy_sub_text', { date: this.$options.filters.formatShortDateWithoutDays(this.voucher.dormancy.start_date) }),
            amount: this.voucher.payment_amount + this.voucher.bonus_amount,
            boldAmount: true,
            hideHint: false,
            hintLinkText: this.$t('components.account_voucher.dormancy.dormancy_hints.link_title'),
            hintTitle: this.$t('components.account_voucher.dormancy.dormancy_hints.title'),
            hintHtmlMessage: this.$t('components.account_voucher.dormancy.dormancy_hints.message', {
              date: this.$options.filters.formatTextDate(this.voucher.dormancy.start_date),
              amount,
              delay: this.voucher.dormancy.settings.last_activity_trigger_in_months,
            }),
          };
        }

        return {
          text: this.$t('components.account_voucher.actual_balance'),
          subText: !this.hasClosureDate ? '' : this.$t('components.account_voucher.my_money_transfer', { date: this.$options.filters.formatShortDate(this.voucher.closure_date) }),
          amount: this.voucher.payment_amount + this.voucher.bonus_amount,
          boldAmount: true,
          hideHint: !this.hasClosureDate,
          hintLinkText: this.$t('components.account_voucher.transfer_hints.link_title'),
          hintTitle: this.$t('components.account_voucher.transfer_hints.title'),
          hintHtmlMessage: this.$t('components.account_voucher.transfer_hints.message', { date: this.$options.filters.formatTextDate(this.voucher.closure_date) }),
        };
      }

      return {
        text: this.$t('components.account_voucher.actual_balance'),
        amount: this.voucher.payment_amount + this.voucher.bonus_amount,
        boldAmount: true,
      };
    },
    voucherContributionProps() {
      if (this.hasDormancyFee) {
        const dormancyAmount = this.$options.filters.currency_full(this.voucher.dormancy.settings.fixed_fee);
        const amount = this.$options.filters.currencySymbol(this.$options.filters.currency(dormancyAmount));

        return {
          text: this.$t('components.account_voucher.contribution'),
          subText: this.$t('components.account_voucher.dormancy.dormancy_sub_text', { date: this.$options.filters.formatShortDateWithoutDays(this.voucher.dormancy.start_date) }),
          amount: this.voucher.payment_amount,
          boldAmount: true,
          hideHint: false,
          hintLinkText: this.$t('components.account_voucher.dormancy.dormancy_hints.link_title'),
          hintTitle: this.$t('components.account_voucher.dormancy.dormancy_hints.title'),
          hintHtmlMessage: this.$t('components.account_voucher.dormancy.dormancy_hints.message', {
            date: this.$options.filters.formatTextDate(this.voucher.dormancy.start_date),
            amount,
            delay: this.voucher.dormancy.settings.last_activity_trigger_in_months,
          }),
        };
      }

      return {
        text: this.$t('components.account_voucher.contribution'),
        subText: !this.hasClosureDate ? '' : this.$t('components.account_voucher.my_money_transfer', { date: this.$options.filters.formatShortDate(this.voucher.closure_date) }),
        amount: this.voucher.payment_amount,
        hideHint: !this.hasClosureDate,
        hintLinkText: this.$t('components.account_voucher.contribution_hints.link_title'),
        hintTitle: this.$t('components.account_voucher.contribution_hints.title'),
        hintHtmlMessage: this.$t('components.account_voucher.contribution_hints.message', { date: this.$options.filters.formatTextDate(this.voucher.closure_date) }),
      };
    },
    voucherBonusProps() {
      const props = {
        text: this.$t('components.account_voucher.bonus'),
        amount: this.voucher.bonus_amount,
      };
      if (this.voucher.bonus_expiration_date) {
        props.subText = this.$t('components.account_voucher.bonus_expiration', { date: this.$options.filters.formatShortDate(this.voucher.bonus_expiration_date) });
        props.hintHtmlMessage = this.$t('components.account_voucher.bonus_hints.message', { date: this.$options.filters.formatTextDate(this.voucher.bonus_expiration_date) });
        props.hintLinkText = this.$t('components.account_voucher.bonus_hints.link_title');
        props.hintTitle = this.$t('components.account_voucher.bonus_hints.title');
      }
      return props;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  padding: 0 calc(var(--gap-small) - 3px);
}

.secondary-section {
  margin-top: var(--gap-large);
  padding: var(--gap-small) var(--gap-xsmall);
  border: var(--border-thin) solid var(--color-border);
}
</style>
