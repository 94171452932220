<template>
  <div class="consumer-gift-card-offer-small-card">
    <v-hover v-slot="{ hover }">
      <small-card-base
        :restricted-to-members="offer.features.restricted_to_members"
        :image="offer.offer_branding.images.showcases[currentLocale][0]"
        :to-url="urlToOffer"
        :class="{ 'on-hover': hover }"
        :show-panier-bleu="currentBrandingPanierBleu"
        :offer-title="offer.offer_branding.label[currentLocale]"
      >
        <template #content>
          <div class="small-card-base-badge">
            <div :style="{ backgroundColor: publicSpaceByPartnerIdColorGetter(offer.partner_id) }" class="hello-gift-badge"><v-icon color="ultra-light">mdi-wallet-giftcard</v-icon></div>
            <div class="hello-category-label">
              <div class="region-text">{{ offer.offer_branding.region_label[currentLocale] }}</div>
              <div class="category-text" v-if="hasMultipleStore">{{ $t('components.consumer_gift_card_offer_small_card.store_count', { store_count: offer.store_count }) }}</div>
            </div>
          </div>

          <h-promotion-progress-bar
            v-if="offer.current_promotion && (offer.current_promotion.status === 'available' || offer.current_promotion.status === 'coming_soon')"
            class="progress-bar"
            :font-size="13"
            :status="offer.current_promotion.status"
            :reward-percent="offer.current_promotion.incentive.bonus_display"
            :expiration="offer.current_promotion.availability.end_date"
            :start="offer.current_promotion.availability.start_date"
            :current-number="offer.current_promotion.availability.inventory.adjusted_avail_payment_amount"
            :total-number="offer.current_promotion.availability.inventory.adjusted_total_payment_amount"
            :min-purchase="offer.current_promotion.incentive.minimum_payment"
            :max-purchase="offer.current_promotion.incentive.adjusted_user_payment_limit"
            restriction-label-key="components.consumer_gift_card_offer_small_card.promotion_restriction"
            mobile
          />

          <div class="description h-text-4" v-else>
            {{ offer.offer_branding.description[currentLocale] }}
          </div>
        </template>
      </small-card-base>
    </v-hover>
  </div>
</template>
<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import { horizontalLogoImageHelper } from '@/composables/image-helper';

export default {
  name: 'consumer-gift-card-offer-small-card',
  setup() {
    return {
      horizontalLogoImageHelper,
    };
  },
  components: {
    SmallCardBase,
  },
  props: {
    offer: {
      type: Object,
    },
  },
  watch: {
    offer() {
      this.payAmount = this.offer.incentive.default_payment;
    },
  },
  data() {
    return {
      payAmount: this.offer.incentive.default_payment,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentBrandingHorizontalLogo', 'publicSpaceByPartnerIdLogoGetter', 'publicSpaceByPartnerIdSlugGetter', 'publicSpaceByPartnerIdBrandingNameGetter', 'publicSpaceByPartnerIdColorGetter']),
    currentBrandingPanierBleu() {
      return _get(this.offer, 'offer_branding.features.panier_bleu', false);
    },
    hasMultipleStore() {
      return this.offer.store_count > 1;
    },
    offerObtain() {
      return (this.offer.incentive.bonus_percent + 1) * this.payAmount;
    },
    amountLeft() {
      return this.remainingAmount(
        this.offer.availability.inventory.total_amount,
        this.offer.availability.inventory.used_amount,
        this.offer.incentive.bonus_display,
        _get(this.offer, 'publisher_inventory.total_amount', undefined),
        _get(this.offer, 'publisher_inventory.used_amount', undefined),
      );
    },
    urlToOffer() {
      return `/${this.publicSpaceByPartnerIdSlugGetter(this.offer.partner_id)}/offers/${this.offer.id}`;
    },
  },
  methods: {
    _get,
    action() {},
    routeToOffer() {
      const partner_id = this.offer.partner_id;
      const offer_id = this.offer.id;
      this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { space: this.publicSpaceByPartnerIdSlugGetter(partner_id), offer_id, _position: { x: 0, y: window.scrollY } } });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.consumer-gift-card-offer-small-card {
  position: relative;
}

* {
  text-decoration: none !important;
}

.on-hover {
  cursor: pointer;
  -webkit-filter: brightness(70%);
  transition: all 0.5s ease;
}
.small-card-base:not(.on-hover) {
  cursor: default;
}

.description {
  font-size: 14px;
  color: var(--color-text-tertiary);
  height: 86px;
  margin-bottom: 20px;
  @include cut-after-x-line(4);
}

.small-card-base-badge {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.hello-category-label {
  font-weight: 600;
  font-size: 14px;
}

.hello-gift-badge {
  background-color: var(--color-branding);
  padding: 8px;
  margin-right: 15px;
  min-width: 42px;
  min-height: 42px;
  // min-width: 57.5px;
  text-align: center;
  line-height: 0.9;
}

.region-text {
  margin-bottom: -4px;
  @include cut-after-x-line(1);
}

.category-text {
  @include cut-after-x-line(1);
}

::v-deep .consumer-gift-card-promotion-title > span {
  background-color: var(--color-error);
  color: white;
  padding: 2px 4px;
  font-size: var(--font-size-label-3);
  line-height: 1.5;
  font-weight: 600;
}
.h-promotion-progress-bar {
  padding: 0px !important;
  background-color: transparent !important;
}
</style>
