import _get from 'lodash/get';
import Vue from 'vue';
import _filter from 'lodash/filter';

export default {
  namespaced: true,
  state: {
    card: null,

    paymentInformation: {},
    showCardSelection: false,
    is_valid_context: false,
    current_redeem_result: {},
    current_redeem_is_successful: false,
    can_reroute: true, // NOTE (LH, PLP): offer activation view reroutes on overlay closure/success, which hijacks routing to my-account in success screen. this is in place to prevent that
  },
  getters: {
    currentCard: (state) => _get(state, 'card', null),
    currentCardStatus: (state) => _get(state, 'card.status', null),

    currentCardIsRenewable: (state) => _get(state, 'card.is_renewable', null),
    currentCardPublicToken: (state) => _get(state, 'card.public_token', null),
    currentCardProgramType: (state) => _get(state, 'card.card_program_type', null),
    currentCardholderId: (state) => _get(state, 'card.cardholder_id', null),

    currentRedeemValidContext: (state) => state.is_valid_context,
    showCardSelection: (state) => state.showCardSelection,
    currentRedeemInformation: (state) => state.paymentInformation,
    currentRedeemResult: (state) => state.current_redeem_result,
    currentRedeemIsSuccessful: (state) => state.current_redeem_is_successful,

    canReroute: (state) => state.can_reroute,
  },
  mutations: {
    reset(state) {
      state.card = null;
      state.paymentInformation = {};
      state.showCardSelection = false;
      state.is_valid_context = false;
      state.current_redeem_is_successful = false;
    },
    setCurrentRedeemResult(state, redeem_result) {
      state.current_redeem_result = redeem_result;
    },
    setShowCardSelection(state, showSelection) {
      state.showCardSelection = showSelection;
    },
    setCurrentCard(state, card) {
      state.card = card;
    },
    setCurrentPaymentInformation(state, currentPaymentInformation) {
      state.paymentInformation = currentPaymentInformation;
      state.is_valid_context = true;
    },
    currentRedeemIsSuccessful(state) {
      state.current_redeem_is_successful = true;
    },
    canReroute(state, can_reroute) {
      state.can_reroute = can_reroute;
    },
  },
  actions: {
    async setCurrentCard({ commit }, card) {
      commit('setCurrentCard', card);
    },

    async getRedeemDetailForOverlay({ rootState, rootGetters, dispatch, commit }, { offer_id, publisher_id, partner_id }) {
      commit('reset');

      let result;
      result = await Vue.prototype.$services.payment.getPreRedeemInfo({
        offer_id,
        publisher_id,
        partner_id,
      });

      result.cards = _filter(result.cards, (card) => ['active', 'pending_activation', 'locked', 'blocked'].includes(card.status) || card.card_program_type !== 'instant');

      commit('setCurrentPaymentInformation', result);
      let currentCard = null;
      let count = 0;
      if (result && result.cards) {
        count = result.cards.length;
        if (result.cards.length > 0) {
          currentCard = result.cards[0];
        }
        if (rootGetters['account/accountHasApplication'] && !rootGetters['account/hasClassicCard']) {
          count = count + 1;
          currentCard = null;
        }
        commit('setShowCardSelection', count > 1);
      }
      commit('setCurrentCard', currentCard);
    },

    async redeem({ commit, dispatch, state }, { code, recaptcha_token, cardholder_id, allow_communication }) {
      await dispatch('security/generateVisitorData', undefined, { root: true });

      const offer = await Vue.prototype.$services.offer.redeemOfferByActivationCode({
        cardholder_id,
        code,
        recaptcha_token,
        acquiring_token: state.paymentInformation.acquiring_token,
        allow_communication,
      });
      commit('setCurrentRedeemResult', offer.voucher);

      await dispatch('account/getAccount', undefined, { root: true });
      commit('currentRedeemIsSuccessful');
      return offer;
    },

    async clearOverlayRedeemStore({ commit }) {
      commit('reset');
    },

    setCanReroute({ commit }, can_reroute) {
      commit('canReroute', can_reroute);
    },
  },
};
