<template>
  <fragment>
    <div class="banner">
      <img class="showcase" :src="promoImage" :alt="$t('components.corpo_intro.img_alt')" />

      <div class="corpo-intro">
        <div class="header">
          <h2>{{ currentOfferName }}</h2>
          <div class="subheader h-text-4">{{ $t('components.corpo_intro.title') }}</div>
        </div>
        <p class="h-text-2 text" v-html="$t('components.corpo_intro.text')"></p>

        <merchants-modal class="link" :offer_id="currentOfferId" />
        <p v-if="isCurrentOfferClosed" class="h-text-2 not-purchasable-message">{{ $t('components.corpo_view.not_purchasable_offer_message') }}</p>
        <p v-if="isCurrentOfferComingSoon" class="offer-label">{{ comingSoonText }}</p>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import MerchantsModal from '@/components/modals/MerchantsModal';
import { showcaseImageHelper } from '@/composables/image-helper';
import _get from 'lodash/get';

export default {
  name: 'corpo-intro',
  setup() {
    return {
      showcaseImageHelper,
    };
  },
  components: {
    MerchantsModal,
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('offer', ['currentOfferShowcasesImage', 'currentOfferName', 'currentOfferId', 'currentOfferStatus', 'currentOffer']),
    promoImage() {
      return showcaseImageHelper(this.currentOfferShowcasesImage[this.currentLocale][0]);
    },
    isCurrentOfferClosed() {
      return this.currentOfferStatus === 'closed';
    },
    isCurrentOfferComingSoon() {
      return this.currentOfferStatus === 'coming_soon' || !_get(this.currentOffer, 'cta.purchase', true);
    },
    formattedStartDateWithHour() {
      return this.$options.filters.formatShortDateWithHour(_get(this.currentOffer, 'availability.start_date'));
    },
    comingSoonText() {
      if (_get(this.currentOffer, 'cta.purchase', true)) {
        return this.$t('components.corpo_view.coming_soon_offer_message', { date: this.formattedStartDateWithHour });
      } else {
        return this.$t('components.corpo_view.coming_soon_offer_message_no_date');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.showcase {
  display: block;
  width: 100%;
  height: auto;

  @media ($md-min) {
    padding: var(--gap) var(--gap) var(--gap) var(--gap-page-side);
    width: auto;
    height: 194px;
  }
}
.banner {
  display: block;

  @media ($md-min) {
    display: flex;
  }
}

.corpo-intro {
  padding: var(--gap) var(--gap-page-side) var(--gap) var(--gap);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    width: 100%;
    margin: 0 0;
  }
}
.not-purchasable-message {
  color: var(--color-error);
  font-weight: 600;
  margin-top: var(--gap);
}

.text {
  margin-top: var(--gap);
  margin-bottom: var(--gap);
  @media ($sm-min) {
    margin-top: var(--gap-small);
    margin-bottom: var(--gap-xsmall);
  }
}
.subheader {
  margin-top: 0;
  font-style: italic;
}

.offer-label {
  margin-top: var(--gap);
}
</style>
