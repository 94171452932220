<template>
  <account-info-bar-base
    :background-color="actionColor"
    display-card-token
    display-card-status
    :public-token="card.public_token"
    :label-public-token="labelCard"
    :card-status="card.status"
    :card-program-type="cardProgramType"
    :display-card-type="true"
    :card-status-notice="notice"
  >
    <activate-card-modal v-if="cardNeedsActivation" :public-token="card.public_token" />
    <renew-card-modal v-else-if="card.is_renewable" :card-program-type="cardProgramType" :public-token="card.public_token" />
  </account-info-bar-base>
</template>

<script>
import AccountInfoBarBase from './components/AccountInfoBarBase.vue';
import ActivateCardModal from './components/ActivateCardModal.vue';
import RenewCardModal from './components/RenewCardModal.vue';

export default {
  name: 'account-info-bar-card',
  components: {
    AccountInfoBarBase,
    ActivateCardModal,
    RenewCardModal,
  },
  props: {
    labelCard: {
      type: String,
      required: true,
    },
    notice: {
      type: String,
    },
    card: {
      public_token: {
        type: String,
        required: true,
      },
      status: {
        type: String,
        required: true,
      },
      is_renewable: {
        type: Boolean,
        default: false,
      },
    },
    cardProgramType: {
      type: String,
    },
  },
  computed: {
    actionColor() {
      if (this.card.is_renewable) {
        return this.$vuetify.theme.themes.light.accent;
      }
      return this.$vuetify.theme.themes.light.primary;
    },
    cardNeedsActivation() {
      return this.card.status === 'pending_activation';
    },
  },
};
</script>
