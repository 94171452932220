<template>
  <div class="corpo-payment-summary">
    <h3 class="header">{{ $t('components.corpo_payment_summary.title') }}</h3>
    <v-progress-circular v-if="contextLoading" :style="{ marginTop: '50px', marginBottom: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" indeterminate color="primary" />
    <fragment v-else>
      <ul class="payment-list">
        <li v-for="(item, index) of paymentItems" :key="`payment-item-${index}`" class="payment-item">
          <span class="item-title" v-if="item.title">{{ item.title }}</span>
          <span class="item-description">{{ item.description }}</span>
          <span>{{ item.value | currency_full | currencySymbol }}</span>
        </li>
      </ul>

      <div class="total-container">
        <span class="total-label">{{ $t('components.corpo_payment_summary.total') }}</span>
        <span class="total-value">{{ contextTotal | currency_full | currencySymbol }}</span>
      </div>
    </fragment>

    <p v-html="$t('components.corpo_payment_summary.subtext')" class="subtext"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _map from 'lodash/map';

export default {
  name: 'corpo-payment-summary',
  props: {},
  data() {
    return {
      panelModel: [],
    };
  },
  computed: {
    ...mapGetters('corpo', ['contextLoading', 'contextOrderDetails', 'contextFeeDetails', 'contextMinimumFee', 'contextTaxDetails', 'contextTotal', 'contextFeeCovered', 'contextOfferDescription']),
    ...mapGetters('ui', ['currentLocale']),
    paymentItems() {
      let items = _map(this.contextOrderDetails, (cod, index) => {
        return {
          title: index === 0 ? this.$options.filters.translateI18nObject(this.contextOfferDescription) : undefined,
          description: cod.description[this.currentLocale],
          value: cod.amount,
        };
      });

      if (!this.contextFeeCovered) {
        items.push(
          {
            title: this.contextMinimumFee
              ? this.$t('components.corpo_payment_summary.payment_items.fees_title_with_minimum_fee', { minimum_fee: this.$options.filters.currencySymbol(this.$options.filters.currency(this.contextMinimumFee)) })
              : this.$t('components.corpo_payment_summary.payment_items.fees_title'),
            description: this.contextFeeDetails && this.contextFeeDetails.description ? this.contextFeeDetails.description[this.currentLocale] : '',
            value: this.contextFeeDetails ? this.contextFeeDetails.amount : 0,
          },
          {
            title: this.$t('components.corpo_payment_summary.payment_items.tax_title'),
            description: this.contextTaxDetails ? this.contextTaxDetails.tax_types.map((t) => this.$options.filters.tax(t)).join(' + ') : '',
            value: this.contextTaxDetails ? this.contextTaxDetails.amount : 0,
          },
        );
      }

      return items;
    },
  },
  methods: {
    toggleDetails() {
      if (this.panelModel.length === 0) {
        this.panelModel = [0];
      } else {
        this.panelModel = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-list {
  padding: 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--color-text-secondary);

  @media ($sm-min) {
    font-size: 1.125rem;
  }
}
.payment-item {
  margin-top: 12px;
}
.item-title {
  width: 100%;
  margin-bottom: 6px;
}
.item-description {
  padding-left: var(--gap-small);
}
.total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--gap) 0 var(--gap-xlarge);
  padding-top: 20px;
  border-top: 2px solid var(--color-border);
}
.total-label {
  font-size: 1.125rem;
  @media ($md-min) {
    font-size: 1.25rem;
  }
}
.total-value {
  font-size: 2rem;
}
.subtext {
  font-size: 0.875rem;
  color: var(--color-text-secondary);
  margin-bottom: 0;
}
.payment-item {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
</style>
