import Vue from 'vue';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

async function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export default {
  namespaced: true,
  state: {
    paymentAmount: null,
    offerPromotionId: null,
    giftCardMessage: '',
    amountAndMessageAreValid: false,
    consumerGiftCardOrderId: null,
    consumerPaymentNotice: {
      en: '',
      fr: '',
    },
    consumerPurchaseOverLimitNotice: {
      en: '',
      fr: '',
    },
    voucherId: null,
    activationCodeId: null,
    orderStatus: null,
    offerCashFlowOptimization: false,

    contextLoading: false,
    orderProcessing: false,
    currentPaymentIsSuccessful: false,

    context: null,
    contextDefaultAmount: null,
    contextMinAmount: null,
    contextMaxAmount: null,
    contextDormancyEnabled: null,
    contextDormancyStartDate: null,
    contextBonusExpirationDate: null,
    contextUserPaymentRemaining: null,
    contextDormancyTrigger: null,
    contextDormancyExtensionInMonths: null,
    contextDormancyFixedFee: null,
    my_cash_amount_max: null,
  },
  getters: {
    offerPromotionId: (state) => state.offerPromotionId,
    paymentAmount: (state) => state.paymentAmount,
    giftCardMessage: (state) => state.giftCardMessage,
    amountAndMessageAreValid: (state) => state.amountAndMessageAreValid,
    voucherId: (state) => state.voucherId,
    orderIsCompleted: (state) => state.orderStatus === 'completed',
    offerCashFlowOptimization: (state) => state.offerCashFlowOptimization,
    contextDefaultAmount: (state) => state.contextDefaultAmount,
    contextMinAmount: (state) => state.contextMinAmount,
    contextMaxAmount: (state) => state.contextMaxAmount,
    contextUserPaymentRemaining: (state) => state.contextUserPaymentRemaining,
    contextBonusExpirationDate: (state) => state.contextBonusExpirationDate,
    contextDormancyEnabled: (state) => state.contextDormancyEnabled,
    contextDormancyStartDate: (state) => state.contextDormancyStartDate,
    contextDormancyTrigger: (state) => state.contextDormancyTrigger,
    contextDormancyExtensionInMonths: (state) => state.contextDormancyExtensionInMonths,
    contextDormancyFixedFee: (state) => state.contextDormancyFixedFee,
    my_cash_amount_max: (state) => state.my_cash_amount_max,
    contextLoading: (state) => state.contextLoading,
    orderProcessing: (state) => state.orderProcessing,
    hasAContextAndContextIsReady: (state) => state.context !== null && !state.contextLoading,
    consumerGiftCardOrderId: (state) => state.consumerGiftCardOrderId,
    consumerPaymentNotice: (state) => state.consumerPaymentNotice,
    consumerPurchaseOverLimitNotice: (state) => state.consumerPurchaseOverLimitNotice,
    activationCodeId: (state) => state.activationCodeId,
    currentPaymentIsSuccessful: (state) => state.currentPaymentIsSuccessful,
  },
  mutations: {
    // NOTE: (SC) Deliberately contains no giftCardMessage or paymentAmount or offerPromotionId.
    clearAcquiringContext(state) {
      state.context = null;
      state.contextLoading = false;
      state.contextDefaultAmount = null;
      state.contextMinAmount = null;
      state.contextMaxAmount = null;
      state.currentPaymentIsSuccessful = false;
      state.orderProcessing = false;
      state.offerCashFlowOptimization = false;
      state.contextDormancyEnabled = null;
      state.contextDormancyStartDate = null;
      state.contextUserPaymentRemaining = null;
      state.contextBonusExpirationDate = null;
      state.contextDormancyTrigger = null;
      state.contextDormancyExtensionInMonths = null;
      state.contextDormancyFixedFee = null;
      state.voucherId = null;
      state.consumerGiftCardOrderId = null;
      state.consumerPaymentNotice = {
        en: '',
        fr: '',
      };
      state.activationCodeId = null;
      state.amountAndMessageAreValid = false;
      // NOTE (LH): do not clear consumerPurchaseOverLimitNotice as it is used outside of the context
    },
    resetContext(state) {
      state.context = null;
      state.contextLoading = false;
      state.contextDefaultAmount = null;
      state.contextMinAmount = null;
      state.contextMaxAmount = null;
      state.currentPaymentIsSuccessful = false;
      state.orderProcessing = false;
      state.offerCashFlowOptimization = false;
      state.contextDormancyEnabled = null;
      state.contextDormancyStartDate = null;
      state.contextBonusExpirationDate = null;
      state.contextDormancyTrigger = null;
      state.contextDormancyExtensionInMonths = null;
      state.contextDormancyFixedFee = null;
      state.contextUserPaymentRemaining = null;
      state.my_cash_amount_max = null;
      state.voucherId = null;
      state.consumerGiftCardOrderId = null;
      state.consumerPaymentNotice = {
        en: '',
        fr: '',
      };
      state.activationCodeId = null;
      state.amountAndMessageAreValid = false;
      state.paymentAmount = null;
      state.offerPromotionId = null;
      state.giftCardMessage = '';
      // NOTE (LH): do not clear consumerPurchaseOverLimitNotice as it is used outside of the context
    },
    setContext(state, context) {
      state.context = context;
      state.offerCashFlowOptimization = context.offer_cash_flow_optimization;
      state.contextDefaultAmount = context.purchase_default_amount;
      state.paymentAmount = state.paymentAmount ? state.paymentAmount : context.purchase_default_amount;
      state.contextMinAmount = _get(context, 'purchase_limit.min_amount', null);
      state.contextMaxAmount = _get(context, 'purchase_limit.max_amount', null);
      state.contextUserPaymentRemaining = _get(context, 'user_payment_remaining', null);
      state.contextBonusExpirationDate = _get(context, 'bonus_expiration_date', null);
      state.contextDormancyStartDate = _get(context, 'dormancy_fee.dormancy_start_date', null);
      state.contextDormancyEnabled = _get(context, 'dormancy_fee.enabled', null);
      state.contextDormancyTrigger = _get(context, 'dormancy_fee.last_activity_trigger_in_months', null);
      state.contextDormancyExtensionInMonths = _get(context, 'dormancy_fee.extension_in_months', null);
      state.contextDormancyFixedFee = _get(context, 'dormancy_fee.fixed_fee', null);
      state.my_cash_amount_max = _get(context, 'my_cash_amount_max', null);
    },
    setContextLoading(state, loading) {
      state.contextLoading = loading;
    },
    setPaymentAmount(state, paymentAmount) {
      state.paymentAmount = paymentAmount;
    },
    setOfferPromotionId(state, offerPromotionId) {
      state.offerPromotionId = offerPromotionId;
    },
    setGiftCardMessage(state, giftCardMessage) {
      state.giftCardMessage = giftCardMessage;
    },
    setAmountAndMessageAreValid(state, amountAndMessageAreValid) {
      state.amountAndMessageAreValid = amountAndMessageAreValid;
    },
    consumerGiftCardOrderId(state, id) {
      state.consumerGiftCardOrderId = id;
    },
    consumerPaymentNotice(state, notice) {
      state.consumerPaymentNotice.en = notice.en;
      state.consumerPaymentNotice.fr = notice.fr;
    },
    consumerPurchaseOverLimitNotice(state, notice) {
      state.consumerPurchaseOverLimitNotice.en = notice.en;
      state.consumerPurchaseOverLimitNotice.fr = notice.fr;
    },
    setOrderProcessing(state, processing) {
      state.orderProcessing = processing;
    },
    setCurrentPaymentIsSuccessful(state, currentPaymentIsSuccessful) {
      state.currentPaymentIsSuccessful = true;
    },
    setVoucherId(state, voucher_id) {
      state.voucherId = voucher_id;
    },
    setActivationCodeId(state, activation_code_id) {
      state.activationCodeId = activation_code_id;
    },
    setOrderStatus(state, orderStatus) {
      state.orderStatus = orderStatus;
    },
  },
  actions: {
    updateConsumerPurchaseLimitNotice({ commit }, notice) {
      commit('consumerPurchaseOverLimitNotice', notice);
    },
    async listConsumerGiftCardVouchersByAccount({}) {
      return Vue.prototype.$services.consumerGiftCard.listConsumerGiftCardVouchersByAccount();
    },
    async acquireConsumerGiftCardContext({ commit, state, rootState, dispatch }, payload) {
      commit('clearAcquiringContext');
      commit('setContextLoading', true);

      if (rootState.security.authenticated && !payload.same_payment) {
        await dispatch('payment/refreshPaymentMethods', undefined, { root: true });
      }

      if (state.offerPromotionId) {
        payload.offer_promotion_id = state.offerPromotionId;
      }

      try {
        const context = await Vue.prototype.$services.consumerGiftCard.acquireConsumerGiftCardContext(_omit(payload, ['same_payment']));
        commit('setContext', context);
      } catch (err) {
        commit('clearAcquiringContext');
        if (err.code === 'errors.http.invalid_offer_promotion_status') {
          return; //(PLP) Prevent error.  This is not an error case now
        }
        throw err;
      } finally {
        commit('setContextLoading', false);
      }
    },

    async acquireConsumerGiftCardContextInPlace({ commit, state, rootState, dispatch }, payload) {
      commit('setContextLoading', true);

      if (rootState.security.authenticated && !payload.same_payment) {
        await dispatch('payment/refreshPaymentMethods', undefined, { root: true });
      }

      if (state.offerPromotionId) {
        payload.offer_promotion_id = state.offerPromotionId;
      }

      try {
        const context = await Vue.prototype.$services.consumerGiftCard.acquireConsumerGiftCardContext(_omit(payload, ['same_payment']));
        commit('setContext', context);
      } catch (err) {
        commit('clearAcquiringContext');
        if (err.code === 'errors.http.invalid_offer_promotion_status') {
          return; //(PLP) Prevent error.  This is not an error case now
        }
        throw err;
      } finally {
        commit('setContextLoading', false);
      }
    },

    async setConsumerGiftCardPaymentAmount({ commit }, amount) {
      commit('setPaymentAmount', amount);
    },
    async setConsumerGiftCardOfferPromotionId({ commit, dispatch }, { id, offer_id, partner_id }) {
      commit('setOfferPromotionId', id);
      dispatch('acquireConsumerGiftCardContextInPlace', { same_payment: true, partner_id, offer_id });
    },
    async setConsumerGiftCardOfferPromotionIdWithoutContext({ commit }, { id }) {
      commit('setOfferPromotionId', id);
    },
    async setGiftCardMessage({ commit }, message) {
      commit('setGiftCardMessage', message);
    },
    async setAmountAndMessageAreValid({ commit }, amountAndMessageAreValid) {
      commit('setAmountAndMessageAreValid', amountAndMessageAreValid);
    },
    async resetPaymentWorking({ commit }) {
      commit('setPaymentWorking', false);
    },
    async pay({ commit, dispatch, state }, { payment_method_token, merchant_ref_number, offer_id, partner_id, allow_communication, recaptcha_token, tracking_link_id, visitor_uuid, my_cash_amount, credit_card_amount, postal_code }) {
      commit('setOrderProcessing', true);

      await dispatch('security/generateVisitorData', undefined, { root: true });

      const payload = {
        payment_method: {},
        merchant_ref_number,
        amount: state.paymentAmount,
        allow_communication,
        acquiring_token: state.context.acquiring_token,
        recaptcha_token,
        tracking_link_id,
        visitor_uuid,
        postal_code,
      };

      if (state.giftCardMessage) {
        payload.personalized_message = state.giftCardMessage;
      }

      payload.payment_method.token = payment_method_token || null;

      if (my_cash_amount) {
        payload.my_cash_amount = my_cash_amount;
      }

      if (credit_card_amount) {
        payload.credit_card_amount = credit_card_amount;
      }

      try {
        const result = await Vue.prototype.$services.consumerGiftCard.createPurchaseConsumerGiftCardOrder(payload);
        await commit('consumerGiftCardOrderId', result.consumer_gift_card_order_id);
        await commit('consumerPaymentNotice', result.consumer_payment_notice);
        await dispatch('account/getAccount', undefined, { root: true });
        commit('setCurrentPaymentIsSuccessful');
      } finally {
        commit('setOrderProcessing', false);
      }
    },
    async getOrderDetails({ commit }, consumer_gift_card_order_id) {
      const { voucher_id, status, activation_code_id } = await Vue.prototype.$services.consumerGiftCard.getOrderDetails(consumer_gift_card_order_id);
      commit('setVoucherId', voucher_id);
      commit('setActivationCodeId', activation_code_id);
      commit('setOrderStatus', status);
    },
    async sendConsumerGiftCardByEmail({}, { voucher_id, recipient_first_name, recipient_email, sender_name, language }) {
      const payload = { recipient_first_name, recipient_email, sender_name, language };
      return Vue.prototype.$services.consumerGiftCard.sendConsumerGiftCardByEmail(voucher_id, payload);
    },
    async downloadGiftCard(context, { voucher_id, filename, lang }) {
      const fileSaver = Vue.prototype.$services.fileSaver;
      const giftCard = await Vue.prototype.$services.consumerGiftCard.downloadGiftCard({ voucher_id, lang });

      if (!giftCard) {
        throw new Error('An error occurred while downloading the pdf');
      }

      await fileSaver.saveAs(giftCard, filename);

      return { success: true };
    },
    async clearConsumerGiftCard({ commit }) {
      commit('resetContext');
    },
    async downloadReceipt(context, { consumer_gift_card_order_id, language }) {
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await Vue.prototype.$services.consumerGiftCard.downloadReceipt(consumer_gift_card_order_id, language);
      await fileSaver.saveAs(fileContent, filename);

      return { success: true };
    },
  },
};
