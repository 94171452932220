<template>
  <div class="account-consumer-gift-card-voucher">
    <img class="main-img" :src="mainImgSrc" :alt="$t('components.account_voucher_consumer_gift_card.img_alt')" />

    <div class="voucher-information">
      <h3 class="voucher-header h-heading-h4">{{ headerText }}</h3>
      <div class="h-label-4-med-italic status-section">{{ status }}</div>
      <voucher-consumer-gift-card-amount-and-links :voucher="voucher" @refreshGiftCardVouchers="refreshGiftCardVouchers" />
    </div>

    <voucher-amount-consumer class="voucher-consumer-gift-card-amount balance-section" v-if="voucher.offer_promotion_id" :voucher="voucher" />
    <div v-else class="balance-section voucher-base-amount-no-promotion">
      <div class="voucher-info amount-style-large">{{ $t('components.account_voucher_consumer_gift_card_amount.actual_balance') }}</div>
      <div class="voucher-amount-no-promotion amount-style-large">{{ calculatedBalance | currency_full | currencySymbol }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { showcaseSmall } from '@/composables/image-helper';
import VoucherConsumerGiftCardAmountAndLinks from '@/components/vouchers/VoucherConsumerGiftCardAmountAndLinks.vue';
import VoucherAmountConsumer from '@/components/vouchers/VoucherAmountConsumer.vue';

export default {
  name: 'account-consumer-gift-card-voucher',
  components: {
    VoucherConsumerGiftCardAmountAndLinks,
    VoucherAmountConsumer,
  },
  setup() {
    return {
      showcaseSmall,
    };
  },
  props: {
    voucher: {
      type: Object,
    },
  },
  methods: {
    refreshGiftCardVouchers() {
      this.$emit('refreshGiftCardVouchers');
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    status() {
      const status_text = `${this.$t('components.account_voucher_consumer_gift_card_amount.status')}`;
      const system_status = this.$t(`components.account_voucher_consumer_gift_card_amount.activation_code_status.${this.voucher.activation_code.system_status}`);
      const send_email_status = this.$t(`components.account_voucher_consumer_gift_card_amount.send_email_status.${this.voucher.activation_code.send_email_status}`);

      return `${status_text}: ${system_status} (${send_email_status})`;
    },
    calculatedBalance() {
      return this.voucher.bonus_amount + this.voucher.payment_amount;
    },
    voucherIsCanceled() {
      return this.voucher.status === 'canceled';
    },
    mainImgSrc() {
      const rawSource = this.voucher.offer_branding.images.showcases[this.currentLocale][0];
      return showcaseSmall(rawSource);
    },
    headerText() {
      return this.voucher.offer_branding.label[this.currentLocale];
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-section {
  padding: 0 calc(var(--gap-small) - 3px);
}
// NOTE: SC: styles and classes copied from VoucherBaseAmount.vue
.voucher-amount-no-promotion {
  font-size: var(--font-size-subheader);
  font-weight: 600;
  line-height: 1;
  margin-left: 8px;
  text-align: right;
  align-self: flex-start;

  &.amount-style-large {
    font-size: 30px;
    font-weight: 700;
  }

  @media ($sm-min) {
    width: 150px;
    flex: 0 0 auto;
    margin-left: 0;
  }
}

.voucher-base-amount-no-promotion {
  display: flex;
  align-items: flex-end;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.account-consumer-gift-card-voucher {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: var(--gap);
  padding-top: var(--gap-large);
  border-top: var(--border-thin) solid var(--color-border);

  @media ($sm-min) {
    flex-flow: row wrap;
  }
}

.voucher-overlay {
  font-weight: bold;
  text-transform: uppercase;
}

.main-img {
  display: block;
  width: 100%;
  max-height: 300px;

  @media ($sm-min) {
    width: 45%;
    max-width: 340px;
    max-height: 170px;
  }
}

.voucher-information {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.merchants-modal {
  justify-content: flex-end;
}

.merchants-button {
  @media ($md-min) {
    margin-top: auto;
  }
}

.voucher-header {
  margin-bottom: var(--gap-xxsmall);
}
.status-section {
  margin-bottom: var(--gap);
}

.offer-label {
  font-style: italic;
  margin-bottom: var(--gap-large);
}

.voucher-consumer-gift-card-amount {
  width: 100%;

  @media ($md-min) {
    width: auto;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.v-overlay {
  top: var(--gap-large);
}
</style>
